FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1024) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('expanded');
	       $(this).find('.sub-menu').slideToggle();
	    })
	}

/* ====================== Recent Projects Mobile Carousel ======================  */
	if(windowWidth < 768) {
		const projectsCarousel = new Swiper('.recent-projects .swiper', {
	        spaceBetween: 15,
	       	slidesPerView: "1.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.recent-projects .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
		});
	}

/* ====================== Logo Row Mobile Carousel ======================  */
	const logoCarousel = new Swiper('.logo-row .logos', {
        spaceBetween: 100,
       	speed: 1000,
       	loop: true,
       	allowTouchMove: false,
       	slidesPerView: '5',
       	disableOnInteraction: false,
				autoplay: {
					delay: 750,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				// pagination: {
				// 	el: '.logo-row .pagination',
				// 	type: 'bullets',
				// 	clickable: true
				// },
			breakpoints: {
				767: {
					spaceBetween: 20,
				},
			}
	});

/* ====================== Dual Logo Carousel ======================  */
	const top_carousel = new Swiper('.top-carousel', {
	    spaceBetween: 150,
		centeredSlides: true,
		speed: 6000,
		autoplay: {
			delay: 1000,
		},
		loop: true,
		slidesPerView:'auto',
		allowTouchMove: false,
		breakpoints: {
			768: {
				spaceBetween: 60,
			},
			1024: {
				spaceBetween: 100,
			}
		}
	});

	const bottom_carousel = new Swiper('.bottom-carousel', {
		spaceBetween: 150,
		centeredSlides: true,
		speed: 6000,
		autoplay: {
			delay: 1000,
			reverseDirection: true
		},
		loop: true,
		slidesPerView:'auto',
		allowTouchMove: false,
		breakpoints: {
			768: {
				spaceBetween: 60,
			},
			1024: {
				spaceBetween: 100,
			}
		}
	});

/* ====================== Testimonial Cards Carousel ======================  */
	if(windowWidth < 1024) {
		const testimonialCards = new Swiper('.testimonial-cards .swiper', {
	        spaceBetween: 30,
	       	slidesPerView: "2.25",
	       	speed: 600,
	       	loop: true,
	       	centeredSlides: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.testimonial-cards .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
			breakpoints: {
			767: {
				spaceBetween: 15,
				slidesPerView: "1.25",
			},
			}
		});
	}

/* ====================== Testimonial Cards Carousel ======================  */
	const testimonialsCarousel = new Swiper('.testimonials-carousel .swiper', {
        spaceBetween: 30,
       	slidesPerView: "1",
       	speed: 600,
       	loop: true,
       	autoplay: {
  			delay: 8000,
  		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
    		el: '.testimonials-carousel .pagination',
    		type: 'bullets',
    		clickable: true
	  	},
	});

/* ====================== Other Recent Work Carousel ======================  */
	if(windowWidth < 768) {
		const projectsCarousel = new Swiper('.case-studies .swiper', {
	        spaceBetween: 15,
	       	slidesPerView: "1.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.case-studies .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
		});
	}
/* ====================== Awards Carousel ======================  */
	if(windowWidth < 768) {
		const awardsCarousel = new Swiper('.awards .swiper', {
	        spaceBetween: 15,
	       	slidesPerView: "1.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 2000,
	  		},
	      // pagination: {
	    	// 	el: '.awards .pagination',
	    	// 	type: 'bullets',
	    	// 	clickable: true
	  		// },
		});
	}

/* ====================== Recent News Mobile Carousel ======================  */
	if(windowWidth < 768) {
		const newsCarousel = new Swiper('.latest-news .swiper', {
	        spaceBetween: 15,
	       	slidesPerView: "1.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.latest-news .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
		});
	}

/* ====================== Recent News Mobile Carousel ======================  */
	if(windowWidth < 768) {
		const newsCarousel = new Swiper('.single-post .swiper', {
	        spaceBetween: 15,
	       	slidesPerView: "1.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.single-post .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
		});
	}

/* ====================== Other Recent Work Mobile Carousel ======================  */
	if(windowWidth < 1024) {
		const otherRecentProjects = new Swiper('.other-recent-work .swiper', {
	        spaceBetween: 15,
	       	slidesPerView: "2.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.other-recent-work .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
			breakpoints: {
			767: {
				spaceBetween: 15,
				slidesPerView: "1.25",
			},
			}
		});
	}

/* ====================== Image Carousel ======================  */
	const imageCarousel = new Swiper('.image-carousel .swiper', {
	    spaceBetween: 110,
	   	slidesPerView: "1.85",
	   	speed: 600,
	   	centeredSlides: true,
	   	loop: true,
	   	//loopedSlides: 3,
	   	autoplay: {
				delay: 8000,
			},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			1279: {
				spaceBetween: 15,
				slidesPerView: "1.25",
			},
		}
	});

/* ====================== Vendlive Content and Image Mobile Carousel ======================  */
	if(windowWidth < 1023) {
		const vendliveContentandImage = new Swiper('.vendlive-content-and-image .swiper', {
	        spaceBetween: 15,
	        autoHeight: true,
	       	slidesPerView: "1.25",
	       	speed: 600,
	       	centeredSlides: true,
	       	loop: true,
	       	autoplay: {
	  			delay: 8000,
	  		},
	          pagination: {
	    		el: '.vendlive-content-and-image .pagination',
	    		type: 'bullets',
	    		clickable: true
	  		},
		});
	}



/* ====================== Image Comparison ======================  */
	$(".image-comparison").twentytwenty({
		default_offset_pct: 0.5, // How much of the before image is visible when the page loads
		orientation: 'vertical', // Orientation of the before and after images ('horizontal' or 'vertical')
		before_label: '', // Set a custom before label
		after_label: '', // Set a custom after label
		no_overlay: true, //Do not show the overlay with before and after
		move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement.
		click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
	});

/* ====================== Accordion ======================  */
	$('.faqs .faq-container .question').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.faqs .faq-container .answer').slideUp();
			$(this).next('.faqs .faq-container .answer').removeClass('active');
		} else {
			$('.faqs .faq-container .question').removeClass('active');
			$('.faqs .faq-container .answer').slideUp();
			$('.faqs .faq-container .answer').removeClass('active');

			$(this).addClass('active');
			$(this).next('.faqs .faq-container .answer').slideDown();
			$(this).next('.faqs .faq-container .answer').addClass('active');
		}
    });


//========== ON SENT HIDE FORM ==========//
	document.addEventListener( 'wpcf7mailsent', function( event ) {
		var formID = event.detail.contactFormId;

		$('.wpcf7-form.sent').parents('.form-container').addClass('mail-sent');
	})

/* ====================== GSAP ANIMATIONS ======================  */
	ScrollTrigger.matchMedia({
		'(min-width:1024px)':function(){
			let parallax = gsap.utils.toArray('.parallax')
			parallax.forEach((item, index) => {
				let tl = gsap.timeline({
				scrollTrigger: {
				trigger: item,
				scrub: true,
				start:"-500 center",
				toggleActions:"restart none none reset",
				end: "1000",
				markers: false
				}
				});
				tl.to(item, {
					y:-100,
					duration:5,
				});
			});
		}
	});

	var triggerPosition = '"top 85%"';
	ScrollTrigger.matchMedia({
		'(max-width:767px)':function(){
			triggerPosition = '"top 85%"';
		}
	});

	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
		scrollTrigger: {
		trigger: item,
		once: true,
		start: triggerPosition,
		toggleActions:"restart none none reset",
		end: "500",
		markers: false,
		toggleClass: 'animateelement'
		}
		});
	});

	let opacity = gsap.utils.toArray('.opacity-anim')
	opacity.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				scrub: true,
				start: 'top 90%',
				end: "+=400",
				markers: false,
				once:true
			}
		});
		tl.to(item, {
			opacity: 1,
			duration:3,
		});
	});

	let fadeupanima = gsap.utils.toArray('.fadeup-anim')
	fadeupanima.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				scrub: true,
				start: 'top 85%',
				stagger: 0.1,
				end: "+=400",
				markers: false,
				once: true
			}
		});
		tl.to(item, {
			opacity: 1,
			y: 0,
			duration:3,
		});
	});

/* ====================== MOBILE NAV SUB MENU DISPLAY ======================  */
  function mobile_nav() {
   if($(window).width() <= 1024) {
      $( ".main-nav > li.menu-item-has-children").on('click', function(e) {
        if(!$(this).hasClass('show-sub')) {
          e.preventDefault();
        }
        $(this).addClass('show-sub');
        $(this).find('.sub-menu').slideDown();
      });
    };
  };

  mobile_nav();
})
